import React, { useEffect } from 'react'
import { RouteComponentProps } from '@scentregroup/shared/next/compat/react-router-dom'
import { Link } from '@scentregroup/shared/link'
import { Card, CallToAction, Paragraph, Row } from '@scentregroup/ui'
import { getCountry } from '../../helpers/get-country'
import { titleFromContentPage } from '../../helpers/title-from-content-page'
import Modules from '../../components/modules'
import PageLanding from '../../components/page-landing'
import Article from '../../components/article'
import withContentPage from '../../containers/with-content-page'
import {
  MetaData as ContentPageMetaData,
  NavigationGroup,
  NavigationItem,
  PromotionFeature,
  Promotion,
  isPromotionFeature,
  ComparisonModule,
  ContentPageDisplayModes,
  isDisplayMode,
} from '@scentregroup/shared/types/content-page'
import { MetaData } from '@scentregroup/shared/types/meta-data'
import pagesHoc from '../pages-hoc'
import analyticsUtils from '../../helpers/analytics-utils'
import { createProductClickEvent } from '../../helpers/analytics-utils/productClick'
import style from './index.module.scss'
import { useAnalytics } from '../../analytics'

interface PromotionalPageProps {
  modules?: (PromotionFeature | Promotion | ComparisonModule)[]
  metaData?: ContentPageMetaData
  navigationMenu?:
    | (NavigationItem | NavigationGroup)[]
    | {
        nav: (NavigationItem | NavigationGroup)[]
      }
  loading?: boolean
  centres?: any
  nationalNavLinks?: any
}

const getProductDetailEvent = (modules: ComparisonModule): void => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.PRODUCT_IMPRESSION,
    ecommerce: {
      currencyCode: 'AUD',
      impressions: modules
        ? modules.entries.map((item, index) => ({
            name: item.productName,
            id: `card100${index + 1}`,
            category: item.productName,
            brand: 'westfield',
            position: index + 1,
            list: item.productName,
          }))
        : [],
    },
  })
}

const PromotionalPage: React.FunctionComponent<
  React.PropsWithChildren<RouteComponentProps & PromotionalPageProps>
> = ({ modules, metaData }): JSX.Element => {
  const comparisonModule =
    modules &&
    (modules.find(({ displayMode }) =>
      isDisplayMode(displayMode, ContentPageDisplayModes.COMPARISON_ITEM)
    ) as ComparisonModule)

  useEffect(() => {
    if (comparisonModule) {
      getProductDetailEvent(comparisonModule)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstModule =
    modules &&
    Boolean(modules?.length) &&
    isPromotionFeature(modules[0]) &&
    (modules[0] as PromotionFeature)

  const restModules = modules && firstModule ? modules.slice(1) : modules

  const dgcComparisonItem =
    comparisonModule &&
    comparisonModule.entries.find(
      ({ productName }) =>
        productName === analyticsUtils.categories.DIGITAL_GIFT_CARD
    )

  const analytics = useAnalytics()
  const ctaOnClick = (): void => {
    analytics.trackEvent({
      type: 'legacy',
      data: {
        event: analyticsUtils.events.USER_CLICK,
        eventCategory: metaData?.title,
        eventLabel: (firstModule || {}).callToAction?.category,
        eventAction: analyticsUtils.categories.FEATURE_CTA,
      },
    })
  }

  return (
    <div className={style.evolved}>
      {/* explicitly sending undefined props for ts vs js reasons */}
      {modules && (
        <>
          {firstModule && (
            <PageLanding
              desktopImage={{ src: firstModule.desktopImageUrl }}
              mobileImage={{ src: firstModule.mobileImageUrl }}
              logo={undefined}
              heading={undefined}
              dataTestId={undefined}
            >
              <Card className={style.card}>
                <Article heading={firstModule.title}>
                  <Paragraph>{firstModule.body}</Paragraph>
                  <Row className={style.callToActionWrapper}>
                    <CallToAction
                      rel="noopener"
                      target="_self"
                      inverted
                      inferior
                      to={firstModule.callToAction.address}
                      element={Link}
                      onClick={ctaOnClick}
                    >
                      {firstModule.callToAction.category}
                    </CallToAction>

                    {dgcComparisonItem ? (
                      <CallToAction
                        rel="noopener"
                        target="_self"
                        to={dgcComparisonItem.callToAction.url}
                        element={Link}
                        onClick={() =>
                          createProductClickEvent(
                            dgcComparisonItem.productName,
                            analyticsUtils.categories.FEATURE_CTA
                          )
                        }
                      >
                        {dgcComparisonItem.callToAction.category}
                      </CallToAction>
                    ) : (
                      <div />
                    )}
                  </Row>
                </Article>
              </Card>
            </PageLanding>
          )}

          <Modules
            modules={restModules}
            className={style.initialContentGroup}
          />
        </>
      )}
    </div>
  )
}

const getMetaData = ({
  metaData,
  staticContext,
}: RouteComponentProps & PromotionalPageProps): MetaData => {
  const COUNTRY = getCountry(staticContext)
  return {
    title: titleFromContentPage(COUNTRY, metaData),
    description: metaData?.shortDescription,
  }
}

export default withContentPage(pagesHoc(PromotionalPage, getMetaData)) as any
